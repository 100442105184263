:root {
    --text-color: #281045;
    --button-secondary: #b271ff;
    --button-secondary-hover: #eddeff;

    --button-primary: #ffd12c;
    --button-primary-hover: #fae682;

    --btn-disabled: #d8d8d8;

    --card-complete: var(--button-primary-hover);
    --card-incomplete: var(--button-secondary-hover);

    --bg-color: #fff9e5;
    --bg-darker: #fff6d7;
    --white: white;

    --danger-color: #ff0000;
    --success-color: #0f866f;
    --success-color-hover: #9adfbc;

    --onboarding-color: #28c074;

    --disabled-text-color: #b1b1b1;
    --disabled-bg-color: rgba(224, 224, 224, 0.3);

    --font-stack-grandstander: "Grandstander", Superclarendon, "Bookman Old Style", "URW Bookman",
        "URW Bookman L", "Georgia Pro", Georgia, serif;

    -webkit-tap-highlight-color: transparent;
}
